import axios from "axios";
import { getToken } from "./Localstorage";
const baseUrl = `${process.env.REPORTING_URL}`;
const { access_token, company_name } = getToken();

const getReport = async (
  url: string,
  requestBody: any,
  fileName: string,
  checkWord: boolean = false
) => {
  try {

    const response = await axios.post(baseUrl + url, requestBody, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        client_name: company_name || "Netsol",
      },
    });

    const fileUrl = await getPublicUrl(response.data.key);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute(
      "download",
      `${fileName}_${new Date().toISOString()}.docx`
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error("Error during file download:", error);
    throw error;
  }
};

const getPublicUrl = async (key: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/documents/configuration/generate-presigned-url`,
      { key } ,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          client_name: company_name || "Netsol",
        },
      }
    );
    return data.template_url;
  } catch (error) {
    throw new Error(`Failed to get public URL: ${error}`);
  }
};

export { getReport };