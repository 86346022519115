import React from "react";
import { Table } from "../../controls/Table/Table";


interface IIncomePosting {
  rows: [];
}

export const IncomePostingResponse: React.FC<IIncomePosting> = ({
  rows,
}: IIncomePosting) => {
  const columns = [] as any;
  columns.push("Sr. #");
  columns.push("Rental #");
  columns.push("Posting Date");
  columns.push("No Of Days");
  columns.push("From Date");
  columns.push("To Date");
  columns.push("Amount");
  if (rows.length > 0) {
    return (
      <div>
        <Table
          columns={columns}
          rows={rows}
          showExport
          fileName="Income Posting"
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};
