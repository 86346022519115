import React from "react";
import { Table } from "../../controls/Table/Table";

interface IRepaymentPlanDetail {
  rows: [];
}

export const RepaymentPlanResponse: React.FC<IRepaymentPlanDetail> = ({
  rows,
}: IRepaymentPlanDetail) => {
  const columns = [] as any;
  columns.push("Rental #");
  columns.push("Rental DueDate");
  columns.push("Opening Principal");
  columns.push("Rental Amount");
  columns.push("Principal");
  columns.push("Interest");
  columns.push("Periodic Interest");
  columns.push("Closing Principal");
  if (rows.length > 0) {
    return (
      <Table
        columns={columns}
        rows={rows}
        showExport
        fileName={"Repayment Plan"}
      />
    );
  } else {
    return <div></div>;
  }
};
